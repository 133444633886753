import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Circles } from 'react-loader-spinner'
import * as Icon from 'react-feather'
import { AuthUserContext, withAuthorization } from '../Session'
import { validateForm } from './validations'
import { ORGANIZATIONS } from '../../constants/organizations'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledAlert
} from 'reactstrap'
import classnames from 'classnames'
import Select from 'react-select'

import jQuery from 'jquery'
const $ = jQuery
window.jQuery = jQuery

const monthNames = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const options = [
  { value: 'Home', label: 'Home' },
  { value: 'Business', label: 'Business' },
  { value: 'Retirement Accounts', label: 'Retirement Accounts' },
  { value: 'Life Insurance', label: 'Life Insurance' },
  { value: 'Investment Accounts', label: 'Investment Accounts' },
  { value: 'Bank Account', label: 'Bank Account' },
  { value: 'Real Estate Investments', label: 'Real Estate Investments' },
  { value: 'Cryptocurrency', label: 'Cryptocurrency' }
]
require('smartwizard/dist/js/jquery.smartWizard.min.js')

//API
const USER = process.env.REACT_APP_BACKEND_URL + 'v2/user'
const WILL = process.env.REACT_APP_BACKEND_URL + 'will'

class WizardVariant extends React.Component {
  constructor(props) {
    super(props)
    let authUser = this.props.authUser.authUser
    this.state = {
      showCreateButton: true,
      showSpinner: false,
      error: null,
      formNumber: 0,
      activeTab: '1',
      name: '',
      email: authUser ? authUser.email : '',
      phoneNumber: '',
      gender: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
      birthMonth: '',
      birthDayString: '',
      birthDay: {
        month: '',
        day: '',
        year: ''
      },
      birthYear: '',
      maritalStatus: '',
      marriageDateString: '',
      marriageDate: {
        month: '',
        day: '',
        year: ''
      },
      spouseType: '',
      spouseName: '',
      personalRep1: '',
      personalRep1Relationship: '',
      personalRep2: '',
      personalRep2Relationship: '',
      guardian1: '',
      guardian1Relationship: '',
      guardian2: '',
      guardian2Relationship: '',
      hasChildren: '',
      numSons: '',
      hasGrandchildren: '',
      numGrandsons: '',
      numGranddaughters: '',
      numDaughters: '',
      numKids: '',
      hasSiblings: '',
      numBrothers: '',
      numSisters: '',
      hasFather: '',
      fatherName: '',
      hasMother: '',
      motherName: '',
      hasPaternalGrandfather: '',
      grandFatherName: '',
      hasFatherHalfSiblings: '',
      numFatherHalfBrothers: '',
      numFatherHalfSisters: '',
      hasMotherHalfSiblings: '',
      numMotherHalfSiblings: '',
      hasNephews: '',
      numNephews: '',
      hasUncles: '',
      numUncles: '',
      profession: '',
      hasArbitration: '',
      arbitratorName: '',
      hasDebt: '',
      hasDonations: '',
      assetClass: '',
      assets: [],
      sonNames: [],
      sonBirthdays: [],
      grandsonNames: [],
      granddaughterNames: [],
      daughterNames: [],
      daughterBirthdays: [],
      brotherNames: [],
      fatherHalfBrotherNames: [],
      fatherHalfSisterNames: [],
      motherHalfSiblingNames: [],
      nephewNames: [],
      uncleNames: [],
      sisterNames: [],
      bequestArr: [],
      bequestLen: '',
      numDebts: '',
      numDonations: '',
      debtNames: [],
      debtRecipientTypes: [],
      debtAddresses: [],
      debtAmounts: [],
      debtReasons: [],
      donationNames: [],
      donationRecipientTypes: [],
      donationAddresses: [],
      donationTypes: [],
      donationAmounts: [],
      lastEdited: new Date().getTime(),
      giftConfirmation: false
    }

    window.addEventListener('resize', function (e) {
      const { innerWidth: width } = window
      if (
        width > 990 &&
        width < 1100 &&
        $('.step-anchor') &&
        $('.step-anchor').length > 0
      ) {
        $('.step-anchor')[0].style.visibility = 'collapse'
      } else if (
        width < 775 &&
        $('.step-anchor') &&
        $('.step-anchor').length > 0
      ) {
        $('.step-anchor')[0].style.visibility = 'collapse'
      } else if ($('.step-anchor') && $('.step-anchor').length > 0) {
        $('.step-anchor')[0].style.visibility = ''
      }
      return true
    })

    // let orgs = ORGANIZATIONS
    // if (this.props.authUser.match.match.params.org) {
    //   let details = orgs[this.props.authUser.match.match.params.org]
    //   if (details) {
    //     Swal.fire({
    //       html:
    //         '<img src="http://localhost:3000/static/media/logo6.89e9e830.jpg" alt="Muslim.Estate" width="150" class="mb-5 mt-5">' +
    //         '<img src="https://icon-library.net/images/two-way-arrow-icon/two-way-arrow-icon-1.jpg" class="ml-4 mb-5 mt-5" alt="SBIA" width="50">' +
    //         '<img src="' +
    //         details.logo +
    //         '" class="ml-4 mb-5 mt-5" alt="SBIA" width="150">' +
    //         '</br>' +
    //         'TEXT TO BE ADDED HERE',
    //       showCloseButton: true,
    //       confirmButtonText: 'Continue to Create Will',
    //       width: '50rem'
    //     })
    //   }
    // }

    $(document).keypress(function (event) {
      if (event.which == '13') {
        event.preventDefault()
      }
    })

    this.handleUserInput = this.handleUserInput.bind(this)
    this.handleAssetsMultiSelect = this.handleAssetsMultiSelect.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.addSon = this.addSon.bind(this)
    this.handleDateInput = this.handleDateInput.bind(this)
    this.handleToggleChange = this.handleToggleChange.bind(this)
    this.createWill = this.createWill.bind(this)
    this.addExternalOrgDetails = this.addExternalOrgDetails.bind(this)
    this.removeGift = this.removeGift.bind(this)
    this.removeDebt = this.removeDebt.bind(this)
    this.gotBackFormNumber = this.gotBackFormNumber.bind(this)
  }

  gotBackFormNumber = (formNumber) => {
    const previousBtn = document.getElementsByClassName('sw-btn-prev')
    if (previousBtn.length > 0) {
      for (let i = 0; i < formNumber; i++) {
        setTimeout(() => {
          previousBtn[0].click()
        }, 100)
      }
    }
  }

  componentWillMount() {
    const authUser = this.props.authUser.authUser
    const uid = authUser.uid
    axios
      .get(`${USER}/${uid}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authUser.token
        }
      })
      .then((response) => {
        if (response.data && response.data.data) {
          let savedData = response.data.data
          savedData.formNumber = 0
          savedData.showCreateButton = true
          savedData.showSpinner = false
          savedData.error = ''
          savedData.giftConfirmation = false
          let assets = []
          let currentAssets = savedData.assets
          if (Array.isArray(currentAssets)) {
            for (let i = 0; i < currentAssets.length; i++) {
              if (typeof currentAssets[i] === 'string') {
                for (let j = 0; j < options.length; j++) {
                  if (options[j].value === currentAssets[i]) {
                    assets.push(options[j])
                    break
                  }
                }
              } else {
                assets.push(currentAssets[i])
              }
            }
          }
          savedData.assets = assets

          this.setState({
            ...savedData
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  handleToggleChange() {
    this.setState({
      giftConfirmation: !this.state.giftConfirmation,
      error: ''
    })
  }

  createWill = (e) => {
    this.setState({
      showCreateButton: false,
      showSpinner: true
    })
    let payload = this.state
    let authUser = this.props.authUser.authUser
    payload.uid = authUser.uid
    payload.email = authUser.email
    payload.lastEdited = new Date().getTime()
    axios
      .post(WILL, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authUser.token
        }
      })
      .then((response) => {
        // Take user to donation page and show success message there
        window.location.href = ROUTES.CONTRIBUTE + '?success=1'

        this.setState({
          showSpinner: false
        })
      })
      .catch((error) => {
        Swal.fire({
          type: 'error',
          title: 'Muslim.Estate ',
          text: 'Error generating Will. Try again later.'
        })
        this.setState({
          showSpinner: false,
          showCreateButton: true
        })
        console.error(error)
      })
  }

  handleDateInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    let formattedKey = name.replace('String', '')
    let selectedDate = value.split('-')
    let formattedDate = {
      month: monthNames[Number(selectedDate[1])],
      day: selectedDate[2],
      year: selectedDate[0]
    }

    this.setState({
      [name]: value,
      [formattedKey]: formattedDate,
      error: null
    })
  }

  handleFormChange = (direction) => {
    let hash = window.location.hash
    if (hash) {
      let internalFormNumber = 0
      if (hash === '#step-2') {
        internalFormNumber = 1
      } else if (hash === '#step-3') {
        internalFormNumber = 3
      } else if (hash === '#step-4') {
        internalFormNumber = 9
      } else if (hash === '#step-5') {
        internalFormNumber = 11
      } else if (hash === '#step-6') {
        internalFormNumber = 12
      }

      this.setState({
        formNumber: internalFormNumber
      })
      return true
    }

    let validationError = validateForm(this.state)
    let result = true
    let formNumber = this.state.formNumber
    if (direction === 'forward') {
      if (validationError) {
        this.setState({
          error: validationError
        })
        return false
      }
      formNumber++
    } else {
      formNumber--
    }

    if (formNumber == 5 && direction === 'forward') {
      if (
        this.state.hasChildren == '0' ||
        (this.state.numSons == '0' && this.state.numDaughters == '0')
      )
        formNumber++
    }
    if (formNumber == 6 && direction === 'forward') {
      if (
        this.state.hasPaternalGrandfather != '1' &&
        this.state.hasFather != '0'
      ) {
        formNumber++
      }
    }

    if (formNumber == 7 && direction === 'forward') {
      if (
        this.state.hasGrandchildren != '1' &&
        this.state.hasFatherHalfSiblings != '1' &&
        this.state.hasNephews != '1' &&
        this.state.hasUncles != '1'
      ) {
        formNumber++
      }
    }

    if (formNumber == 8 && direction === 'forward') {
      if (
        (this.state.numBrothers == '' || this.state.numBrothers == '0') &&
        (this.state.numSisters == '' || this.state.numSisters == '0') &&
        (this.state.numGrandsons == '' || this.state.numGrandsons == '0') &&
        (this.state.numGranddaughters == '' ||
          this.state.numGranddaughters == '0') &&
        (this.state.numFatherHalfBrothers == '' ||
          this.state.numFatherHalfBrothers == '0') &&
        (this.state.numFatherHalfSisters == '' ||
          this.state.numFatherHalfSisters == '0') &&
        (this.state.numMotherHalfSiblings == '' ||
          this.state.numMotherHalfSiblings == '0') &&
        (this.state.numNephews == '' || this.state.numNephews == '0') &&
        (this.state.numUncles == '' || this.state.numUncles == '0')
      ) {
        formNumber++
      }
    }

    this.setState({
      formNumber: formNumber,
      error: null
    })

    switch (formNumber) {
      case 0: // Only Previous Transition Possible:: From Personal1 to Instructions
        result = true
        break
      case 1: // From Instructions to Personal1
        document.getElementById('personal1').style.display = 'block'
        document.getElementById('personal2').style.display = 'none'
        if (direction === 'forward') result = true
        else result = false
        break
      case 2: // Personal 1 to 2
        document.getElementById('personal1').style.display = 'none'
        document.getElementById('personal2').style.display = 'block'
        if (direction === 'forward') result = false
        else result = true
        break
      case 3: // Personal 2 to Family 1
        document.getElementById('family1').style.display = 'block'
        document.getElementById('family2').style.display = 'none'
        document.getElementById('family3').style.display = 'none'
        document.getElementById('family4').style.display = 'none'
        document.getElementById('family5').style.display = 'none'
        document.getElementById('family6').style.display = 'none'
        if (direction === 'forward') result = true
        else result = false
        break
      case 4: // Family 1 to 2
        document.getElementById('family1').style.display = 'none'
        document.getElementById('family2').style.display = 'block'
        document.getElementById('family3').style.display = 'none'
        document.getElementById('family4').style.display = 'none'
        document.getElementById('family5').style.display = 'none'
        document.getElementById('family6').style.display = 'none'
        result = false
        break
      case 5: // Family 2 to 3
        document.getElementById('family1').style.display = 'none'
        document.getElementById('family2').style.display = 'none'
        document.getElementById('family3').style.display = 'block'
        document.getElementById('family4').style.display = 'none'
        document.getElementById('family5').style.display = 'none'
        document.getElementById('family6').style.display = 'none'
        result = false
        break
      case 6: // Family 3 to 4
        document.getElementById('family1').style.display = 'none'
        document.getElementById('family2').style.display = 'none'
        document.getElementById('family3').style.display = 'none'
        document.getElementById('family4').style.display = 'block'
        document.getElementById('family5').style.display = 'none'
        document.getElementById('family6').style.display = 'none'
        result = false
        break
      case 7: // Family 4 to 5
        document.getElementById('family1').style.display = 'none'
        document.getElementById('family2').style.display = 'none'
        document.getElementById('family3').style.display = 'none'
        document.getElementById('family4').style.display = 'none'
        document.getElementById('family5').style.display = 'block'
        document.getElementById('family6').style.display = 'none'
        result = false
        break
      case 8: // Family 5 to 6
        document.getElementById('family1').style.display = 'none'
        document.getElementById('family2').style.display = 'none'
        document.getElementById('family3').style.display = 'none'
        document.getElementById('family4').style.display = 'none'
        document.getElementById('family5').style.display = 'none'
        document.getElementById('family6').style.display = 'block'
        if (direction === 'forward') result = false
        else result = true
        break
      case 9: // From Family 6 to Finance 1
        document.getElementById('finance1').style.display = 'block'
        document.getElementById('finance2').style.display = 'none'
        if (direction === 'forward') result = true
        else result = false
        break
      case 10: // Finance 1 to 2
        document.getElementById('finance1').style.display = 'none'
        document.getElementById('finance2').style.display = 'block'
        if (direction === 'forward') result = false
        else result = true
        break
      case 11: // Finance 2 to Agents
        result = true
        break
      case 12: // Agents to Gift 1
        document.getElementById('gift1').style.display = 'block'
        document.getElementById('gift2').style.display = 'none'
        if (direction === 'forward') result = true
        else result = false
        break
      case 13: // Gift 1 to 2
        document.getElementById('gift1').style.display = 'none'
        document.getElementById('gift2').style.display = 'block'
        result = false
        if (direction === 'forward') result = false
        else result = true
        break
      case 14: // Gift 2 to Review
        result = true
        break
    }

    $(window).scrollTop(0)

    $('.sw-container.tab-content').css('min-height', '0px')

    if (formNumber > 1 && direction === 'forward') {
      let pdfDataEntry = this.state
      let authUser = this.props.authUser.authUser
      pdfDataEntry.uid = authUser.uid
      pdfDataEntry.email = authUser.email
      pdfDataEntry.lastEdited = new Date().getTime()
      axios
        .put(USER, pdfDataEntry, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authUser.token
          }
        })
        .then((response) => {
          console.info('UPDATE COMPLETE')
        })
        .catch((error) => {
          console.error(error)
        })
    }

    if ($('.close') && $('.close').length > 0) {
      for (let i = 0; i < $('.close').length; i++) {
        $('.close')[i].style.display = 'none'
      }
    }

    return result
  }

  handleUserInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState({
      [name]: value,
      error: null
    })
  }

  handleAssetsMultiSelect(selectedAssets) {
    this.setState({
      assets: Array.isArray(selectedAssets) ? selectedAssets : [],
      error: null
    })
  }

  componentDidMount() {
    var _ = this
    $(this.refs.smartwizard)
      .smartWizard({
        theme: 'arrows', // default OR arrows
        showStepURLhash: false,
        toolbarSettings: {}
      })
      .on('leaveStep', function (e, anchorObject, stepNumber, stepDirection) {
        return _.handleFormChange(stepDirection)
      })

    const { innerWidth: width } = window
    if (
      width > 990 &&
      width < 1100 &&
      $('.step-anchor') &&
      $('.step-anchor').length > 0
    ) {
      $('.step-anchor')[0].style.visibility = 'collapse'
    } else if (
      width < 775 &&
      $('.step-anchor') &&
      $('.step-anchor').length > 0
    ) {
      $('.step-anchor')[0].style.visibility = 'collapse'
    } else if ($('.step-anchor') && $('.step-anchor').length > 0) {
      $('.step-anchor')[0].style.visibility = ''
    }
  }

  addGrandson = () => {
    let name = document.getElementById('grandsonName').value
    let { grandsonNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    grandsonNames.push(name)
    this.setState({
      grandsonNames: grandsonNames,
      error: null
    })
  }

  removeGrandson = (e) => {
    let { grandsonNames } = this.state
    grandsonNames.splice(e.target.value, 1)
    this.setState({
      grandsonNames: grandsonNames,
      error: null
    })
  }
  addGranddaughter = () => {
    let name = document.getElementById('granddaughterName').value
    let { granddaughterNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    granddaughterNames.push(name)
    this.setState({
      granddaughterNames: granddaughterNames,
      error: null
    })
  }

  removeGranddaughter = (e) => {
    let { granddaughterNames } = this.state
    granddaughterNames.splice(e.target.value, 1)
    this.setState({
      granddaughterNames: granddaughterNames,
      error: null
    })
  }
  addBrother = () => {
    let name = document.getElementById('brotherName').value
    let { brotherNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    brotherNames.push(name)
    this.setState({
      brotherNames: brotherNames,
      error: null
    })
    document.getElementById('brotherName').value = ''
  }

  removeBrother = (e) => {
    let { brotherNames } = this.state
    brotherNames.splice(e.target.value, 1)
    this.setState({
      brotherNames: brotherNames,
      error: null
    })
  }
  addSister = () => {
    let name = document.getElementById('sisterName').value
    let { sisterNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    sisterNames.push(name)
    this.setState({
      sisterNames: sisterNames,
      error: null
    })
  }

  removeSister = (e) => {
    let { sisterNames } = this.state
    sisterNames.splice(e.target.value, 1)
    this.setState({
      sisterNames: sisterNames,
      error: null
    })
  }
  addGift = () => {
    const donationRecipientType = $('#donationRecipientType').val()
    const donationName = $('#donationName').val()
    const donationType = $('#donationType').val()
    const donationAmount = $('#donationAmount').val()

    if (donationRecipientType.length === 0 || !donationRecipientType.trim()) {
      this.setState({
        error: 'Provide donation recipient type.'
      })
      return false
    }
    if (donationName.length === 0 || !donationName.trim()) {
      this.setState({
        error: 'Provide name.'
      })
      return false
    }
    if (donationAmount.length === 0 || !donationAmount.trim()) {
      this.setState({
        error: 'Provide donation amount.'
      })
      return false
    }
    if (donationType.length === 0 || !donationType.trim()) {
      this.setState({
        error: 'Provide debt description.'
      })
      return false
    }
    let donationRecipientTypes = this.state.donationRecipientTypes
    let donationNames = this.state.donationNames
    let donationAmounts = this.state.donationAmounts
    let donationTypes = this.state.donationTypes

    donationRecipientTypes.push(donationRecipientType)
    donationNames.push(donationName)
    donationAmounts.push(donationAmount)
    donationTypes.push(donationType)

    this.setState({
      donationRecipientTypes: donationRecipientTypes,
      donationNames: donationNames,
      donationAmounts: donationAmounts,
      donationTypes: donationTypes,
      error: null
    })

    document.getElementById('donationRecipientType').value = ''
    document.getElementById('donationName').value = ''
    document.getElementById('donationAmount').value = ''
    document.getElementById('donationType').value = ''
    return true
  }

  removeGift = (e) => {
    let donationRecipientTypes = this.state.donationRecipientTypes
    let donationNames = this.state.donationNames
    let donationAmounts = this.state.donationAmounts
    let donationTypes = this.state.donationTypes

    donationRecipientTypes.splice(e.target.value, 1)
    donationNames.splice(e.target.value, 1)
    donationAmounts.splice(e.target.value, 1)
    donationTypes.splice(e.target.value, 1)

    this.setState({
      donationRecipientTypes: donationRecipientTypes,
      donationNames: donationNames,
      donationAmounts: donationAmounts,
      donationTypes: donationTypes,
      error: null
    })
  }

  addSon = () => {
    let name = document.getElementById('sonName').value
    let birthdate = document.getElementById('sonBirthday').value.split('-')
    let { sonNames, sonBirthdays } = this.state
    sonNames.push(name)
    sonBirthdays.push({
      month: monthNames[Number(birthdate[1])],
      day: birthdate[2],
      year: birthdate[0]
    })

    this.setState({
      sonNames: sonNames,
      sonBirthdays: sonBirthdays,
      error: null
    })
    document.getElementById('sonName').value = ''
    document.getElementById('sonBirthday').value = ''
  }

  removeSon = (e) => {
    let { sonNames, sonBirthdays } = this.state
    sonNames.splice(e.target.value, 1)
    sonBirthdays.splice(e.target.value, 1)

    this.setState({
      sonNames: sonNames,
      sonBirthdays: sonBirthdays,
      error: null
    })
  }

  addDaughter = () => {
    let name = document.getElementById('daughterName').value
    let birthdate = document.getElementById('daughterBirthday').value.split('-')
    let { daughterNames, daughterBirthdays } = this.state
    daughterNames.push(name)
    daughterBirthdays.push({
      month: monthNames[Number(birthdate[1])],
      day: birthdate[2],
      year: birthdate[0]
    })

    this.setState({
      daughterNames: daughterNames,
      daughterBirthdays: daughterBirthdays,
      error: null
    })
    document.getElementById('daughterName').value = ''
    document.getElementById('daughterBirthday').value = ''
  }

  removeDaughter = (e) => {
    let { daughterNames, daughterBirthdays } = this.state
    daughterNames.splice(e.target.value, 1)
    daughterBirthdays.splice(e.target.value, 1)

    this.setState({
      daughterNames: daughterNames,
      daughterBirthdays: daughterBirthdays,
      error: null
    })
  }

  addUncle = () => {
    let name = document.getElementById('uncleName').value
    let { uncleNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    uncleNames.push(name)
    this.setState({
      uncleNames: uncleNames,
      error: null
    })
  }

  removeUncle = (e) => {
    let { uncleNames } = this.state
    uncleNames.splice(e.target.value, 1)

    this.setState({
      uncleNames: uncleNames,
      error: null
    })
  }

  addFatherHalfBrother = () => {
    let name = document.getElementById('fatherHalfBrotherName').value
    let { fatherHalfBrotherNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    fatherHalfBrotherNames.push(name)
    this.setState({
      fatherHalfBrotherNames: fatherHalfBrotherNames,
      error: null
    })
  }

  removefatherHalfBrother = (e) => {
    let { fatherHalfBrotherNames } = this.state
    fatherHalfBrotherNames.splice(e.target.value, 1)

    this.setState({
      fatherHalfBrotherNames: fatherHalfBrotherNames,
      error: null
    })
  }

  addFatherHalfSistes = () => {
    let name = document.getElementById('fatherHalfSisterName').value
    let { fatherHalfSisterNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    fatherHalfSisterNames.push(name)
    this.setState({
      fatherHalfSisterNames: fatherHalfSisterNames,
      error: null
    })
  }

  removefatherHalfSister = (e) => {
    let { fatherHalfSisterNames } = this.state
    fatherHalfSisterNames.splice(e.target.value, 1)

    this.setState({
      fatherHalfSisterNames: fatherHalfSisterNames,
      error: null
    })
  }

  addMotherHalfSiblings = () => {
    let name = document.getElementById('motherHalfSiblingName').value
    let { motherHalfSiblingNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    motherHalfSiblingNames.push(name)
    this.setState({
      motherHalfSiblingNames: motherHalfSiblingNames,
      error: null
    })
  }

  removemotherHalfSibling = (e) => {
    let { motherHalfSiblingNames } = this.state
    motherHalfSiblingNames.splice(e.target.value, 1)

    this.setState({
      motherHalfSiblingNames: motherHalfSiblingNames,
      error: null
    })
  }

  addNephew = () => {
    let name = document.getElementById('nephewName').value
    let { nephewNames } = this.state
    if (!name || name == '' || !name.trim()) {
      this.setState({
        error: 'Enter valid name.'
      })
    }
    nephewNames.push(name)
    this.setState({
      nephewNames: nephewNames,
      error: null
    })
  }

  removeNephew = (e) => {
    let { nephewNames } = this.state
    nephewNames.splice(e.target.value, 1)

    this.setState({
      nephewNames: nephewNames,
      error: null
    })
  }

  addDebt = (e) => {
    const debtOwed = $('#debtOwed').val()
    const debtCreditor = $('#debtCreditor').val()
    const debtAmount = $('#debtAmount').val()
    const debtDescription = $('#debtDescription').val()

    if (debtOwed.length === 0 || !debtOwed.trim()) {
      this.setState({
        error: 'Provide debt owner.'
      })
      return false
    }
    if (debtCreditor.length === 0 || !debtCreditor.trim()) {
      this.setState({
        error: "Provide creditor's name."
      })
      return false
    }
    if (debtAmount.length === 0 || !debtAmount.trim()) {
      this.setState({
        error: 'Provide debt amount.'
      })
      return false
    }
    if (debtDescription.length === 0 || !debtDescription.trim()) {
      this.setState({
        error: 'Provide debt description.'
      })
      return false
    }
    let debtRecipientTypes = this.state.debtRecipientTypes
    let debtNames = this.state.debtNames
    let debtAmounts = this.state.debtAmounts
    let debtReasons = this.state.debtReasons

    debtRecipientTypes.push(debtOwed)
    debtNames.push(debtCreditor)
    debtAmounts.push(debtAmount)
    debtReasons.push(debtDescription)

    this.setState({
      debtRecipientTypes: debtRecipientTypes,
      debtNames: debtNames,
      debtAmounts: debtAmounts,
      debtReasons: debtReasons,
      error: null
    })
    return true
  }

  removeDebt = (e) => {
    let debtRecipientTypes = this.state.debtRecipientTypes
    let debtNames = this.state.debtNames
    let debtAmounts = this.state.debtAmounts
    let debtReasons = this.state.debtReasons

    debtRecipientTypes.splice(e.target.value, 1)
    debtNames.splice(e.target.value, 1)
    debtAmounts.splice(e.target.value, 1)
    debtReasons.splice(e.target.value, 1)

    this.setState({
      debtRecipientTypes: debtRecipientTypes,
      debtNames: debtNames,
      debtAmounts: debtAmounts,
      debtReasons: debtReasons,
      error: null
    })
  }

  addExternalOrgDetails() {
    let orgs = ORGANIZATIONS
    let org = this.props.authUser.match.match.params.org
    if (orgs[org]) {
      document.getElementById('donationRecipientType').value =
        orgs[org].recipientType
      document.getElementById('donationName').value = orgs[org].name
    }
  }

  helpText(id, message) {
    return (
      <UncontrolledAlert
        color={'info'}
        id={id}
        style={{
          fontSize: '16px',
          backgroundColor: '#002E5D',
          display: 'none'
        }}
      >
        <div className="alert-message alert-undismissable">{message}</div>
      </UncontrolledAlert>
    )
  }

  showInfoText(id) {
    const infoText = document.getElementById(id)
    const iconHelpCircle = document.getElementById(id + 'HelpCircle')
    if (infoText && infoText.style.display === 'block') {
      infoText.style.display = 'none'
      iconHelpCircle.style.color = ''
      iconHelpCircle.style.fill = ''
      //      iconHelpCircle.style.height="24"
      //      iconHelpCircle.style.width="24"
    } else {
      infoText.style.display = 'block'
      iconHelpCircle.style.color = 'white'
      iconHelpCircle.style.fill = '#21B0DD'
      //      iconHelpCircle.style.height="30"
      //      iconHelpCircle.style.width="30"
    }
    if ($('.close') && $('.close').length > 0) {
      for (let i = 0; i < $('.close').length; i++) {
        $('.close')[i].style.display = 'none'
      }
    }
  }

  render = () => {
    let orgs = ORGANIZATIONS
    let org = this.props.authUser.match.match.params.org
    let marriageDetail = (
      <div>
        <div className="mb-4">
          <h5 className="text-primary">
            <em>Marriage Date</em>
          </h5>
          <h4>
            {this.state.marriageDate.month} {this.state.marriageDate.day},{' '}
            {this.state.marriageDate.year}
          </h4>
        </div>
        <div className="mb-4">
          <h5 className="text-primary">
            <em>Spouse Name</em>
          </h5>
          <h4>{this.state.spouseName}</h4>
        </div>
      </div>
    )

    let sonNames = this.state.sonNames
    let sonBirthdays = this.state.sonBirthdays
    let sonDetails = []
    for (let i = 0; i < sonNames.length; i++) {
      sonDetails.push(
        <Row key={i} form>
          <Col md={6}>
            <div className="mb-4">
              <h5 className="text-primary">
                <em>Son {i + 1} Name</em>
              </h5>
              <h4>{sonNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <h5 className="text-primary">
                <em>Son {i + 1} Date Of Birth</em>
              </h5>
              <h4>
                {sonBirthdays[i].month} {sonBirthdays[i].day},{' '}
                {sonBirthdays[i].year}
              </h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeSon}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let daughterNames = this.state.daughterNames
    let daughterBirthdays = this.state.daughterBirthdays
    let daughterDetails = []
    for (let i = 0; i < this.state.daughterNames.length; i++) {
      daughterDetails.push(
        <Row key={i} form>
          <Col md={6}>
            <div>
              <h5 className="text-primary">
                <em>Daughter {i + 1} Name</em>
              </h5>
              <h4>{daughterNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div>
              <h5 className="text-primary">
                <em>Daughter {i + 1} Date Of Birth</em>
              </h5>
              <h4>
                {daughterBirthdays[i].month} {daughterBirthdays[i].day},{' '}
                {daughterBirthdays[i].year}
              </h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeDaughter}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let brotherNames = this.state.brotherNames
    let brotherDetails = []
    for (let i = 0; i < this.state.brotherNames.length; i++) {
      brotherDetails.push(
        <Row key={i}>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Brother {i + 1} Name</em>
              </h5>
              <h4>{brotherNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeBrother}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
          <Col md={5} />
        </Row>
      )
    }

    let sisterNames = this.state.sisterNames
    let sisterDetails = []
    for (let i = 0; i < this.state.sisterNames.length; i++) {
      sisterDetails.push(
        <Row key={i}>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Sister {i + 1} Name</em>
              </h5>
              <h4>{sisterNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeSister}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let grandsonNames = this.state.grandsonNames
    let grandsonDetails = []
    for (let i = 0; i < this.state.grandsonNames.length; i++) {
      grandsonDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Grandson {i + 1} Name</em>
              </h5>
              <h4>{grandsonNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeGrandson}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let granddaughterNames = this.state.granddaughterNames
    let granddaughterDetails = []
    for (let i = 0; i < this.state.granddaughterNames.length; i++) {
      granddaughterDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Grand Daughter {i + 1} Name</em>
              </h5>
              <h4>{granddaughterNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeGranddaughter}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let fatherHalfBrotherNames = this.state.fatherHalfBrotherNames
    let fatherHalfBrotherDetails = []
    for (let i = 0; i < fatherHalfBrotherNames.length; i++) {
      fatherHalfBrotherDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Father Halfbrother {i + 1} Name</em>
              </h5>
              <h4>{fatherHalfBrotherNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removefatherHalfBrother}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let fatherHalfSisterNames = this.state.fatherHalfSisterNames
    let fatherHalfSisterDetails = []
    for (let i = 0; i < fatherHalfSisterNames.length; i++) {
      fatherHalfSisterDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Father Halfsister {i + 1} Name</em>
              </h5>
              <h4>{fatherHalfSisterNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removefatherHalfSister}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let motherHalfSiblingNames = this.state.motherHalfSiblingNames
    let motherHalfSiblingDetails = []
    for (let i = 0; i < motherHalfSiblingNames.length; i++) {
      motherHalfSiblingDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Mother Halfsibling {i + 1} Name</em>
              </h5>
              <h4>{motherHalfSiblingNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removemotherHalfSibling}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let nephewNames = this.state.nephewNames
    let nephewDetails = []
    for (let i = 0; i < nephewNames.length; i++) {
      nephewDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Nephew {i + 1} Name</em>
              </h5>
              <h4>{nephewNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeNephew}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let uncleNames = this.state.uncleNames
    let uncleDetails = []
    for (let i = 0; i < uncleNames.length; i++) {
      uncleDetails.push(
        <Row>
          <Col md={4}>
            <div key={i} className="mb-4">
              <h5 className="text-primary">
                <em>Uncle {i + 1} Name</em>
              </h5>
              <h4>{uncleNames[i]}</h4>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                display: this.state.formNumber == '14' ? 'none' : 'block'
              }}
              className="mb-4"
            >
              <Button
                className="mt-3"
                onClick={this.removeUncle}
                value={i}
                color="danger"
              >
                Remove
              </Button>
            </div>
          </Col>
        </Row>
      )
    }

    let debtRecipientTypes = this.state.debtRecipientTypes
    let debtNames = this.state.debtNames
    let debtAmounts = this.state.debtAmounts
    let debtReasons = this.state.debtReasons
    let debtsDetails = []
    for (let i = 0; i < this.state.debtNames.length; i++) {
      debtsDetails.push(
        <div key={i} className="mb-4">
          <h5 className="text-primary">
            <em>Debt {i + 1} Name</em>
          </h5>
          <h4 className="mb-3">{debtNames[i]}</h4>

          <h5 className="text-primary">
            <em>Debt {i + 1} Recipient Type</em>
          </h5>
          <h4 className="mb-3">{debtRecipientTypes[i]}</h4>

          <h5 className="text-primary">
            <em>Debt {i + 1} Amount</em>
          </h5>
          <h4 className="mb-3">{debtAmounts[i]}</h4>

          <h5 className="text-primary">
            <em>Debt {i + 1} Detail</em>
          </h5>
          <h4>{debtReasons[i]}</h4>

          <div
            style={{
              display: this.state.formNumber == '14' ? 'none' : 'block'
            }}
            className="mb-4"
          >
            <Button
              className="mt-3"
              onClick={this.removeDebt}
              value={i}
              color="danger"
            >
              Remove
            </Button>
          </div>
        </div>
      )
    }

    let donationTypes = this.state.donationTypes
    let donationNames = this.state.donationNames
    let donationAmounts = this.state.donationAmounts
    let donationRecipientTypes = this.state.donationRecipientTypes
    let donationDetails = []
    for (let i = 0; i < this.state.donationNames.length; i++) {
      donationDetails.push(
        <div key={i} className="mb-4">
          <h5 className="text-primary">
            <em>Donation {i + 1} Name</em>
          </h5>
          <h4 className="mb-3">{donationNames[i]}</h4>

          <h5 className="text-primary">
            <em>Donation {i + 1} Recipient Type</em>
          </h5>
          <h4 className="mb-3">{donationRecipientTypes[i]}</h4>

          <h5 className="text-primary">
            <em>Donation {i + 1} Amount</em>
          </h5>
          <h4 className="mb-3">{donationAmounts[i]}</h4>

          <h5 className="text-primary">
            <em>Donation {i + 1} Type</em>
          </h5>
          <h4>{donationTypes[i]}</h4>

          <div
            style={{
              display: this.state.formNumber == '14' ? 'none' : 'block'
            }}
            className="mb-4"
          >
            <Button
              className="mt-3"
              onClick={this.removeGift}
              value={i}
              color="danger"
            >
              Remove Donation {i + 1}
            </Button>
          </div>
        </div>
      )
    }
    return (
      <div
        ref="smartwizard"
        data-number={this.state.formNumber}
        className={`wizard mb-4`}
      >
        <ul>
          <li id="step-11">
            <a href={`#step-1`} disabled>
              Welcome
              <br />
            </a>
          </li>
          <li id="step-22">
            <a href={`#step-2`}>
              Personal
              <br />
            </a>
          </li>
          <li id="step-33">
            <a href={`#step-3`}>
              Family
              <br />
            </a>
          </li>
          <li id="step-44">
            <a href={`#step-4`}>
              Financial
              <br />
            </a>
          </li>
          <li id="step-55">
            <a href={`#step-5`}>
              Agents
              <br />
            </a>
          </li>

          <li id="step-66">
            <a href={`#step-6`}>
              Bequests
              <br />
            </a>
          </li>
          <li id="step-77">
            <a href={`#step-7`}>
              Review
              <br />
            </a>
          </li>
        </ul>
        <div>
          <div id={`step-1`}>
            <Card>
              <CardHeader>
                <h1>Welcome! Let’s walk through the process.</h1>
                <Row className="mt-3 mr-3" form>
                  <Col>
                    <p>
                      Below is a brief summary of what to expect as you progress
                      through your will making process.
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="mt-2">
                      <h3>1. Personal Information</h3>
                      <big>
                        <p>
                          We’ll start by asking for basic information about you.
                          This allows us to personalize your will.
                        </p>
                      </big>
                    </div>
                    <div className="mt-4">
                      <h3>2. Family</h3>
                      <big>
                        <p>
                          Next we’ll find out about your family. This
                          information allows us to determine your heirs which
                          we’ll use to calculate the inheritance shares for each
                          under Islamic law.
                        </p>
                      </big>
                    </div>
                    <div className="mt-4">
                      <h3>3. Financial</h3>
                      <big>
                        <p>
                          We’ll gather some basic information to understand your
                          financial picture - in particular any outstanding
                          debts that need to be specified in your will.
                        </p>
                      </big>
                    </div>
                    <div className="mt-4">
                      <h3>4. Agents</h3>
                      <big>
                        <p>
                          Name the individual(s) whom you’d like to handle your
                          affairs after your death.
                        </p>
                      </big>
                    </div>
                    <div className="mt-4">
                      <h3>5. Bequests</h3>
                      <big>
                        <p>
                          This is your opportunity to use your will to do good.
                          A beautiful aspect of Islamic law is the opportunity
                          to use up to 1/3rd of your estate as charitable
                          bequests.
                        </p>{' '}
                      </big>
                    </div>
                    <div className="mt-4">
                      <h3>6. Execution</h3>
                      <big>
                        <p>
                          Review your work. After you submit it, our tool will
                          process your answers and instantly generate your will
                          along with instructions for how to make it legally
                          valid. You can also schedule time with an attorney.
                        </p>
                      </big>
                      <big>
                        <p>
                          If you’d like, you can also purchase time for a
                          private consult with one of our Islamic Estate
                          Planning Attorneys
                        </p>
                      </big>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>

          <div id={`step-2`}>
            <Card id="personal1">
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Tell us about you
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      What is your full legal name?{' '}
                    </Label>
                    <Icon.HelpCircle
                      id="legalNameHelpCircle"
                      className="float-right"
                      onClick={this.showInfoText.bind(this, 'legalName')}
                    />
                    {this.helpText(
                      'legalName',
                      "Please enter your name as it appears on your driver's license."
                    )}
                    <Input
                      bsSize="lg"
                      className="input-group-lg"
                      type="name"
                      name="name"
                      placeholder="e.g John Doe"
                      value={this.state.name}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="text-lg">What is your gender? </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="gender"
                      bsSize="lg"
                      value={this.state.gender}
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">Date of Birth</Label>
                    <Input
                      type="date"
                      bsSize="lg"
                      name="birthDayString"
                      value={this.state.birthDayString}
                      onChange={this.handleDateInput}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : (
                  ''
                )}
              </CardFooter>
            </Card>

            <Card id="personal2" style={{ display: 'none' }}>
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Tell us about you
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="mb-4">
                    <Label className="text-lg">What is your adress? </Label>
                    <Icon.HelpCircle
                      id="legalAddressHelpCircle"
                      className="float-right"
                      onClick={this.showInfoText.bind(this, 'legalAddress')}
                    />
                    {this.helpText(
                      'legalAddress',
                      'If you have more than one residence, input the residence that you consider your permanent home here. Factors to consider include: where you are registered to vote, where you have a driver’s license, and where you claim your permanent residence when filing taxes.If you have more than one residence, input the residence that you consider your permanent home here. Factors to consider include: where you are registered to vote, where you have a driver’s license, and where you claim your permanent residence when filing taxes.'
                    )}
                    <Input
                      bsSize="lg"
                      type="text"
                      name="address"
                      placeholder="e.g  329 West Julian ST Apt 123"
                      value={this.state.address}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">What is your city? </Label>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="city"
                      placeholder="e.g San Jose"
                      value={this.state.city}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">What is your State? </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="state"
                      bsSize="lg"
                      value={this.state.state}
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">Postal Code</Label>
                    <Input
                      bsSize="lg"
                      type="number"
                      placeholder="e.g 95110"
                      maxLength="5"
                      name="postalCode"
                      value={this.state.postalCode}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : (
                  ''
                )}
              </CardFooter>
            </Card>
          </div>

          <div id={`step-3`}>
            <div>
              <UncontrolledAlert
                color="primary"
                style={{ fontSize: '16px', backgroundColor: '#002E5D' }}
              >
                <div className="alert-message">
                  <p>
                    This section asks a series of questions about your family
                    that will enable us to determine your heirs under Islamic
                    law and how much each of them will inherit. Please note that
                    only Muslim relatives are entitled to inherit in Islam as a
                    matter of right. Because this tool is only designed for
                    basic cases, please make sure each of the relatives you list
                    on this page self-identify as Muslim.
                  </p>
                </div>
              </UncontrolledAlert>

              <Card id="family1">
                <CardHeader>
                  <CardTitle
                    style={{
                      fontSize: '24px',
                      fontWeight: '300',
                      textAlign: 'center'
                    }}
                    tag="h5"
                  >
                    Tell us about your family 1/6
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup className="mb-4">
                      <Label className="text-lg">
                        What is your marital status?{' '}
                      </Label>
                      <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        name="maritalStatus"
                        bsSize="lg"
                        value={this.state.maritalStatus}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Marital Status</option>
                        <option>Single, Never Married</option>
                        <option>Married</option>
                        <option>Divorced</option>
                        <option>Widowed</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label className="text-lg">
                        Do you have any living children?{' '}
                      </Label>
                      <Icon.HelpCircle
                        id="livingChildrenHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(this, 'livingChildren')}
                      />
                      {this.helpText(
                        'livingChildren',
                        'Under Islamic Inheritance law, (1) male children inherit double the share of similarly situated female children and (2) only biological children inherit as a matter of right. If you have any adopted children or stepchildren, you may leave bequests for them through the discretionary (wasiyah) share at a later step in this interview.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasChildren"
                        name="hasChildren"
                        bsSize="lg"
                        value={this.state.hasChildren}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label className="text-lg">
                        Is your biological father alive?
                      </Label>
                      <Icon.HelpCircle
                        id="biologicalFatherHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(
                          this,
                          'biologicalFather'
                        )}
                      />
                      {this.helpText(
                        'biologicalFather',
                        'Unlike most western societies, a surviving father is entitled to inherit from his children.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasFather"
                        name="hasFather"
                        bsSize="lg"
                        value={this.state.hasFather}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label className="text-lg">
                        Is your biological mother alive?
                      </Label>
                      <Icon.HelpCircle
                        id="biologicalMotherHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(
                          this,
                          'biologicalMother'
                        )}
                      />
                      {this.helpText(
                        'biologicalMother',
                        'Unlike most western societies, a surviving mother is entitled to inherit from her children.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasMother"
                        name="hasMother"
                        bsSize="lg"
                        value={this.state.hasMother}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label className="text-lg">
                        Do you have any living full siblings?{' '}
                      </Label>
                      <Icon.HelpCircle
                        id="livingFullSiblingsHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(
                          this,
                          'livingFullSiblings'
                        )}
                      />
                      {this.helpText(
                        'livingFullSiblings',
                        'Under Islamic Inheritance law, siblings may be entitled to inherit if the deceased is not survived by his or her father or does not have any male children.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasSiblings"
                        name="hasSiblings"
                        bsSize="lg"
                        value={this.state.hasSiblings}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : (
                    ''
                  )}
                </CardFooter>
              </Card>

              <Card id="family2" style={{ display: 'none' }}>
                <CardHeader>
                  <CardTitle
                    style={{
                      fontSize: '24px',
                      fontWeight: '300',
                      textAlign: 'center'
                    }}
                    tag="h5"
                  >
                    Tell us about your family 2/6
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup
                      style={{
                        display:
                          this.state.maritalStatus == 'Married'
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Label className="text-lg">Date of Marriage</Label>
                      <Input
                        type="date"
                        bsSize="lg"
                        name="marriageDateString"
                        value={this.state.marriageDateString}
                        onChange={this.handleDateInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.maritalStatus == 'Married'
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                    >
                      <Label className="text-lg">
                        What is your spouse full name?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="name"
                        name="spouseName"
                        placeholder="e.g  John Doe"
                        value={this.state.spouseName}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="childQuestion"
                    >
                      <Label className="text-lg">
                        How many living sons do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numSons"
                        value={this.state.numSons}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="childQuestion"
                    >
                      <Label className="text-lg">
                        How many living daughters do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numDaughters"
                        value={this.state.numDaughters}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display: this.state.hasFather == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="fatherQuestion"
                    >
                      <Label className="text-lg">
                        What is your father's name?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="name"
                        name="fatherName"
                        placeholder="e.g John Doe"
                        value={this.state.fatherName}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display: this.state.hasMother == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="motherQuestion"
                    >
                      <Label className="text-lg">
                        What is your mother's name?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="name"
                        name="motherName"
                        placeholder="e.g Alisa Doe"
                        value={this.state.motherName}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasSiblings == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="motherQuestion"
                    >
                      <Label className="text-lg">
                        How Many living full brothers do you have?
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numBrothers"
                        value={this.state.numBrothers}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasSiblings == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="motherQuestion"
                    >
                      <Label className="text-lg">
                        How Many living full sisters do you have?
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numSisters"
                        value={this.state.numSisters}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display: this.state.hasFather == '0' ? 'block' : 'none'
                      }}
                      className="mb-4"
                    >
                      <Label className="text-lg">
                        Is your paternal grandfather alive?
                      </Label>
                      <Icon.HelpCircle
                        id="paternalGrandfatherHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(
                          this,
                          'paternalGrandfather'
                        )}
                      />
                      {this.helpText(
                        'paternalGrandfather',
                        'A paternal grandfather only inherits if the father is deceased.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasPaternalGrandfather"
                        name="hasPaternalGrandfather"
                        bsSize="lg"
                        value={this.state.hasPaternalGrandfather}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : (
                    ''
                  )}
                </CardFooter>
              </Card>

              <Card id="family3" style={{ display: 'none' }}>
                <CardHeader>
                  <CardTitle
                    style={{
                      fontSize: '24px',
                      fontWeight: '300',
                      textAlign: 'center'
                    }}
                    tag="h5"
                  >
                    Tell us about your family 3/6
                  </CardTitle>
                </CardHeader>
                <CardBody></CardBody>
                <CardBody>
                  <Form
                    style={{
                      display: this.state.hasChildren == '1' ? 'block' : 'none'
                    }}
                  >
                    {sonDetails}
                    {this.state.numSons > 0 &&
                    this.state.sonNames.length < this.state.numSons ? (
                      <FormGroup className="mb-4">
                        <h3>Add Son Information</h3>
                        <Label className="text-lg">
                          Son {this.state.sonNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="sonName"
                          id="sonName"
                        />
                        <Label className="text-lg mt-4">
                          Son {this.state.sonNames.length + 1} Birthdate
                        </Label>
                        <Input
                          type="date"
                          bsSize="lg"
                          name="sonBirthday"
                          id="sonBirthday"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addSon}
                          color="primary"
                        >
                          Add Son {this.state.sonNames.length + 1} Detail
                        </Button>
                        <Button
                          className="mt-4 ml-2"
                          onClick={() =>
                            this.setState({
                              numSons: (
                                parseInt(this.state.numSons) - 1
                              ).toString()
                            })
                          }
                          color="danger"
                        >
                          Remove
                        </Button>
                      </FormGroup>
                    ) : (
                      <Button
                        className="mt-2 mb-4"
                        onClick={() =>
                          this.setState({
                            numSons: (
                              parseInt(this.state.numSons) + 1
                            ).toString()
                          })
                        }
                        color="secondary"
                      >
                        Add Another Son
                      </Button>
                    )}
                    {daughterDetails}
                    {this.state.numDaughters > 0 &&
                    this.state.daughterNames.length <
                      this.state.numDaughters ? (
                      <FormGroup className="mb-4">
                        <h3>Add Daughter Information</h3>
                        <Label className="text-lg mt-4">
                          Daughter {this.state.daughterNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="daughterName"
                          id="daughterName"
                        />
                        <Label className="text-lg mt-4">
                          Daughter {this.state.daughterNames.length + 1}{' '}
                          Birthdate
                        </Label>
                        <Input
                          type="date"
                          bsSize="lg"
                          name="daughterBirthday"
                          id="daughterBirthday"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addDaughter}
                          color="primary"
                        >
                          Add Daughter {this.state.daughterNames.length + 1}{' '}
                          Detail
                        </Button>
                        <Button
                          className="mt-4 ml-2"
                          onClick={() =>
                            this.setState({
                              numDaughters: (
                                parseInt(this.state.numDaughters) - 1
                              ).toString()
                            })
                          }
                          color="danger"
                        >
                          Remove
                        </Button>
                      </FormGroup>
                    ) : (
                      <Button
                        className="mt-2 mb-4 ml-2"
                        onClick={() =>
                          this.setState({
                            numDaughters: (
                              parseInt(this.state.numDaughters) + 1
                            ).toString()
                          })
                        }
                        color="secondary"
                      >
                        Add Another Daughter
                      </Button>
                    )}
                  </Form>
                </CardBody>
                <CardFooter>
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : (
                    ''
                  )}
                </CardFooter>
              </Card>

              <Card id="family4" style={{ display: 'none' }}>
                <CardHeader>
                  <CardTitle
                    style={{
                      fontSize: '24px',
                      fontWeight: '300',
                      textAlign: 'center'
                    }}
                    tag="h5"
                  >
                    Tell us about your family 4/6
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup
                      style={{
                        display:
                          this.state.hasPaternalGrandfather == '1'
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                      id="grandfatherQuestion"
                    >
                      <Label className="text-lg">
                        What is your grandfather's name?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="name"
                        name="grandFatherName"
                        placeholder="e.g John Doe"
                        value={this.state.grandFatherName}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasFather == '0' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Do you have grandchildren?{' '}
                      </Label>
                      <Icon.HelpCircle
                        id="grandchildrenHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(this, 'grandchildren')}
                      />
                      {this.helpText(
                        'grandchildren',
                        'Only list children from a son as only the children of a son inherit from their grandparent(s) provided that the grandparent is not survived by one or more living sons.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasGrandchildren"
                        name="hasGrandchildren"
                        bsSize="lg"
                        value={this.state.hasGrandchildren}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasFather == '0' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Do you have any living half siblings from your father?{' '}
                      </Label>
                      <Icon.HelpCircle
                        id="fatherHalfSiblingsHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(
                          this,
                          'fatherHalfSiblings'
                        )}
                      />
                      {this.helpText(
                        'fatherHalfSiblings',
                        'In rare cases, half-siblings will be entitled to inherit.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasFatherHalfSiblings"
                        name="hasFatherHalfSiblings"
                        bsSize="lg"
                        value={this.state.hasFatherHalfSiblings}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasFather == '0' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Do you have any living half siblings from your mother?{' '}
                      </Label>
                      <Icon.HelpCircle
                        id="motherHalfSiblingsHelpCircle"
                        className="float-right"
                        onClick={this.showInfoText.bind(
                          this,
                          'motherHalfSiblings'
                        )}
                      />
                      {this.helpText(
                        'motherHalfSiblings',
                        'In rare cases, half-siblings will be entitled to inherit.'
                      )}
                      <CustomInput
                        type="select"
                        id="hasMotherHalfSiblings"
                        name="hasMotherHalfSiblings"
                        bsSize="lg"
                        value={this.state.hasMotherHalfSiblings}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasFather == '0' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Do you have any living nephews from a full brother?{' '}
                      </Label>
                      <CustomInput
                        type="select"
                        id="hasNephews"
                        name="hasNephews"
                        bsSize="lg"
                        value={this.state.hasNephews}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>

                    <FormGroup
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasFather == '0' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Do you have any living uncles from your father's side?
                      </Label>
                      <CustomInput
                        type="select"
                        id="hasNephews"
                        name="hasUncles"
                        bsSize="lg"
                        value={this.state.hasUncles}
                        onChange={this.handleUserInput}
                      >
                        <option value="">Select Answer</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </CustomInput>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : (
                    ''
                  )}
                </CardFooter>
              </Card>

              <Card id="family5" style={{ display: 'none' }}>
                <CardHeader>
                  <CardTitle
                    style={{
                      fontSize: '24px',
                      fontWeight: '300',
                      textAlign: 'center'
                    }}
                    tag="h5"
                  >
                    Tell us about your family 5/6
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup
                      style={{
                        display:
                          this.state.hasGrandchildren == '1' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                      id="grandChildrenQuestion"
                    >
                      <Label className="text-lg">
                        How many grandsons from your sons do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numGrandsons"
                        defaultValue="0"
                        value={this.state.numGrandsons}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasGrandchildren == '1' &&
                          (this.state.numSons == '' ||
                            this.state.numSons == '0')
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                      id="grandChildrenQuestion"
                    >
                      <Label className="text-lg">
                        How many granddaughters from your sons do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numGranddaughters"
                        defaultValue="0"
                        value={this.state.numGranddaughters}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasFatherHalfSiblings == '1'
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                      id="halfSiblingsQuestion"
                    >
                      <Label className="text-lg">
                        How many half brothers from your father do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numFatherHalfBrothers"
                        defaultValue="0"
                        value={this.state.numFatherHalfBrothers}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasFatherHalfSiblings == '1'
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                      id="halfSiblingsQuestion"
                    >
                      <Label className="text-lg">
                        How many half sisters from your father do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numFatherHalfSisters"
                        defaultValue="0"
                        value={this.state.numFatherHalfSisters}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display:
                          this.state.hasMotherHalfSiblings == '1'
                            ? 'block'
                            : 'none'
                      }}
                      className="mb-4"
                      id="halfSiblingsQuestion"
                    >
                      <Label className="text-lg">
                        How many half siblings from your mother do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numFatherHalfSisters"
                        defaultValue="0"
                        value={this.state.numFatherHalfSisters}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display: this.state.hasNephews == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="nephewQuestion"
                    >
                      <Label className="text-lg">
                        How many nephews from your full brother do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numNephews"
                        defaultValue="0"
                        value={this.state.numNephews}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>

                    <FormGroup
                      style={{
                        display: this.state.hasUncles == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                      id="uncleQuestion"
                    >
                      <Label className="text-lg">
                        How many living uncles from your father's do you have?{' '}
                      </Label>
                      <Input
                        bsSize="lg"
                        type="number"
                        name="numUncles"
                        defaultValue="0"
                        value={this.state.numUncles}
                        onChange={this.handleUserInput}
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : (
                    ''
                  )}
                </CardFooter>
              </Card>

              <Card id="family6" style={{ display: 'none' }}>
                <CardHeader>
                  <CardTitle
                    style={{
                      fontSize: '24px',
                      fontWeight: '300',
                      textAlign: 'center'
                    }}
                    tag="h5"
                  >
                    Tell us about your family 6/6
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    {brotherDetails}
                    {this.state.numBrothers > 0 &&
                    this.state.brotherNames.length < this.state.numBrothers ? (
                      <FormGroup className="mb-4">
                        <h3>Add Brothers Details</h3>
                        <Label className="text-lg">
                          Brother {this.state.brotherNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="brotherName"
                          id="brotherName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addBrother}
                          color="primary"
                        >
                          Add Brother {this.state.brotherNames.length + 1} Name
                        </Button>
                        <Button
                          className="mt-4 ml-2"
                          onClick={() =>
                            this.setState({
                              numBrothers: (
                                parseInt(this.state.numBrothers) - 1
                              ).toString()
                            })
                          }
                          color="danger"
                        >
                          Remove
                        </Button>
                      </FormGroup>
                    ) : (
                      <Button
                        className="mt-2 mb-4 ml-2"
                        onClick={() =>
                          this.setState({
                            numBrothers: (
                              parseInt(this.state.numBrothers) + 1
                            ).toString()
                          })
                        }
                        color="secondary"
                      >
                        Add Another Brother
                      </Button>
                    )}
                    {sisterDetails}
                    {this.state.numSisters > 0 &&
                    this.state.sisterNames.length < this.state.numSisters ? (
                      <FormGroup className="mb-4">
                        <h3>Add Sisters Details</h3>
                        <Label className="text-lg">
                          Sister {this.state.sisterNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="sisterName"
                          id="sisterName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addSister}
                          color="primary"
                        >
                          Add Sister {this.state.sisterNames.length + 1} Name
                        </Button>
                        <Button
                          className="mt-4 ml-2"
                          onClick={() =>
                            this.setState({
                              numSisters: (
                                parseInt(this.state.numSisters) - 1
                              ).toString()
                            })
                          }
                          color="danger"
                        >
                          Remove
                        </Button>
                      </FormGroup>
                    ) : (
                      <Button
                        className="mt-2 mb-4 ml-2"
                        onClick={() =>
                          this.setState({
                            numSisters: (
                              parseInt(this.state.numSisters) + 1
                            ).toString()
                          })
                        }
                        color="secondary"
                      >
                        Add Another Sister
                      </Button>
                    )}

                    {this.state.numGrandsons > 0 &&
                    this.state.grandsonNames.length <
                      this.state.numGrandsons ? (
                      <FormGroup className="mb-4">
                        <h3>Add Grandson Details</h3>
                        <Label className="text-lg">
                          Grandson {this.state.grandsonNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="grandsonName"
                          id="grandsonName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addGrandson}
                          color="primary"
                        >
                          Add Grandson {this.state.grandsonNames.length + 1}{' '}
                          Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {grandsonDetails}
                    {this.state.numGranddaughters > 0 &&
                    this.state.granddaughterNames.length <
                      this.state.numGranddaughters ? (
                      <FormGroup className="mb-4">
                        <h3>Add Granddaughters Details</h3>
                        <Label className="text-lg">
                          Granddaughter{' '}
                          {this.state.granddaughterNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="granddaughterName"
                          id="granddaughterName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addGranddaughter}
                          color="primary"
                        >
                          Add Granddaughter{' '}
                          {this.state.granddaughterNames.length + 1} Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {granddaughterDetails}
                    {this.state.numFatherHalfBrothers > 0 &&
                    this.state.fatherHalfBrotherNames.length <
                      this.state.numFatherHalfBrothers ? (
                      <FormGroup className="mb-4">
                        <h3>Add Father Halfbrothers Details</h3>
                        <Label className="text-lg">
                          Father Halfbrother{' '}
                          {this.state.fatherHalfBrotherNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="fatherHalfBrotherName"
                          id="fatherHalfBrotherName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addFatherHalfBrother}
                          color="primary"
                        >
                          Add Father Halfbrother{' '}
                          {this.state.fatherHalfBrotherNames.length + 1} Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {fatherHalfBrotherDetails}
                    {this.state.numFatherHalfSisters > 0 &&
                    this.state.fatherHalfSisterNames.length <
                      this.state.numFatherHalfSisters ? (
                      <FormGroup className="mb-4">
                        <h3>Add Father Halfsisters Details</h3>
                        <Label className="text-lg">
                          Father Halfbrother{' '}
                          {this.state.fatherHalfSisterNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="fatherHalfSisterName"
                          id="fatherHalfSisterName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addFatherHalfSistes}
                          color="primary"
                        >
                          Add Father Halfsister{' '}
                          {this.state.fatherHalfSisterNames.length + 1} Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {fatherHalfSisterDetails}
                    {this.state.numMotherHalfSiblings > 0 &&
                    this.state.motherHalfSiblingNames.length <
                      this.state.numMotherHalfSiblings ? (
                      <FormGroup className="mb-4">
                        <h3>Add Mother Halfsiblings Details</h3>
                        <Label className="text-lg">
                          Granddaughter{' '}
                          {this.state.motherHalfSiblingNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="motherHalfSiblingName"
                          id="motherHalfSiblingName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addMotherHalfSiblings}
                          color="primary"
                        >
                          Add Mother Halfsibling{' '}
                          {this.state.motherHalfSiblingNames.length + 1} Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {motherHalfSiblingDetails}
                    {this.state.numNephews > 0 &&
                    this.state.nephewNames.length < this.state.numNephews ? (
                      <FormGroup className="mb-4">
                        <h3>Add Nephews Details</h3>
                        <Label className="text-lg">
                          Nephew {this.state.nephewNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="nephewName"
                          id="nephewName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addNephew}
                          color="primary"
                        >
                          Add Nephew {this.state.nephewNames.length + 1} Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {nephewDetails}
                    {this.state.numUncles > 0 &&
                    this.state.uncleNames.length < this.state.numUncles ? (
                      <FormGroup className="mb-4">
                        <h3>Add Uncle Details</h3>
                        <Label className="text-lg">
                          Uncle {this.state.uncleNames.length + 1} Name
                        </Label>
                        <Input
                          type="name"
                          bsSize="lg"
                          name="uncleName"
                          id="uncleName"
                        />
                        <Button
                          className="mt-4"
                          onClick={this.addUncle}
                          color="primary"
                        >
                          Add Uncle {this.state.uncleNames.length + 1} Name
                        </Button>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                    {uncleDetails}
                  </Form>
                </CardBody>
                <CardFooter>
                  {this.state.error ? (
                    <p className="text-danger">{this.state.error}</p>
                  ) : (
                    ''
                  )}
                </CardFooter>
              </Card>
            </div>
          </div>

          <div id={`step-4`}>
            <Card id="finance1">
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Let's talk about money
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      What's the approximate total value of everything you own?{' '}
                    </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="assetClass"
                      value={this.state.assetClass}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select total value</option>
                      <option value="Less than $250,000">
                        Less than $250,000
                      </option>
                      <option value="$250,000-$500,000">
                        $250,000-$500,000
                      </option>
                      <option value="$500,000-$1,000,000">
                        $500,000-$1,000,000
                      </option>
                      <option value="$1,000,000-$2,000,000">
                        $1,000,000-$2,000,000
                      </option>
                      <option value="$2,000,000-$5,000,000">
                        $2,000,000-$5,000,000
                      </option>
                      <option value="Over $5,000,000">Over $5,000,000</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">What is your profession? </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="profession"
                      value={this.state.profession}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select profession</option>
                      <option value="Physician/Doctor">Physician/Doctor</option>
                      <option value="Other medical professional">
                        Other medical professional
                      </option>
                      <option value="Business">Business</option>
                      <option value="Finance/Law">Finance/Law</option>
                      <option value="Education">Education</option>
                      <option value="Engineering">Engineering</option>
                      <option value="IT">IT</option>
                      <option value="Homemaker">Homemaker</option>
                      <option value="Other">Other</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      Which of the following assets do you own? Check all that
                      apply.
                    </Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={options}
                      bsSize="lg"
                      value={this.state.assets}
                      name="assets"
                      onChange={this.handleAssetsMultiSelect}
                      isMulti
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      Do you have any outstanding debts? This includes items
                      such as mortgages, car loans, private loans, etc.
                    </Label>
                    <Icon.HelpCircle
                      id="debtsHelpCircle"
                      className="float-right"
                      onClick={this.showInfoText.bind(this, 'debts')}
                    />
                    {this.helpText(
                      'debts',
                      'Under Islamic Inheritance law, a decedent’s debts are to be paid first before any discretionary or mandatory distributions are paid to the heirs. You can choose to list any outstanding debts you have here so your personal representative/executor will know to satisfy those debts.'
                    )}
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="hasDebt"
                      value={this.state.hasDebt}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select value</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: this.state.hasDebt == '1' ? 'block' : 'none'
                    }}
                    className="mb-4"
                    id="childQuestion"
                  >
                    <Label className="text-lg">
                      How many outstanding debts do you have?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="number"
                      name="numDebts"
                      value={this.state.numDebts}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : (
                  ''
                )}
              </CardFooter>
            </Card>

            <Card id="finance2" style={{ display: 'none' }}>
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Debt Section
                </CardTitle>
              </CardHeader>

              <CardBody>
                {this.state.hasDebt === '1' ? (
                  <div>
                    <div className="mb-5">{debtsDetails}</div>

                    {this.state.debtNames.length < this.state.numDebts ? (
                      <div>
                        <Form>
                          <h4>
                            Add Debt {this.state.debtNames.length + 1} Form
                          </h4>
                          <FormGroup>
                            <Label className="text-lg">
                              This Debt is owed to?{' '}
                            </Label>
                            <CustomInput
                              type="select"
                              id="debtOwed"
                              name="debtOwed"
                            >
                              <option value="">Select Entity</option>
                              <option value="Individual">
                                An individual (eg. "my neighbor Yusuf")
                              </option>
                              <option value="Organization">
                                An organization (eg. "University of Southern
                                California")
                              </option>
                            </CustomInput>
                          </FormGroup>

                          <FormGroup>
                            <Label className="text-lg">
                              What is your creditor's name?{' '}
                            </Label>
                            <Input
                              type="text"
                              id="debtCreditor"
                              name="debtCreditor"
                              placeholder="e.g Alisa Doe"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="text-lg">
                              What is the Amount in $?{' '}
                            </Label>
                            <Input
                              type="number"
                              id="debtAmount"
                              name="debtAmount"
                              placeholder="Amount in Dollars($)"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label className="text-lg">
                              Debt in description?{' '}
                            </Label>
                            <Input
                              type="text"
                              id="debtDescription"
                              name="debtDescription"
                              placeholder="Debt Description"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Button
                              onClick={this.addDebt.bind(this)}
                              color="primary"
                            >
                              Add Debt {this.state.debtNames.length + 1}
                            </Button>
                          </FormGroup>
                        </Form>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <p>You have no outstanding debts. Continue to next step.</p>
                )}
              </CardBody>
              <CardFooter>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : (
                  ''
                )}
              </CardFooter>
              <br />
            </Card>
          </div>

          <div id={`step-5`}>
            <UncontrolledAlert
              color="primary"
              style={{ fontSize: '16px', backgroundColor: '#002E5D' }}
            >
              <div className="alert-message">
                <p>
                  Please note that because this tool is designed for simple
                  cases, some options are preselected for you. In the event of
                  your death, the individual you select to manage your affairs
                  and distribute your assets to heirs is known as your personal
                  representative or executor. Please provide two people: a
                  primary personal representative and an alternate.
                </p>
              </div>
            </UncontrolledAlert>

            <Card>
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Choosing your Agents
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      What is your first personal representative full name?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="name"
                      name="personalRep1"
                      placeholder="e.g John Doe"
                      value={
                        this.state.maritalStatus === 'Married'
                          ? this.state.spouseName
                          : this.state.personalRep1
                      }
                      disabled={this.state.maritalStatus === 'Married'}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      What is your relationship with this person?{' '}
                    </Label>
                    <CustomInput
                      type="select"
                      id="personalRep1Relationship"
                      name="personalRep1Relationship"
                      value={
                        this.state.maritalStatus === 'Married'
                          ? 'Spouse'
                          : this.state.personalRep1Relationship
                      }
                      disabled={this.state.maritalStatus === 'Married'}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select relationship</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                      <option value="Uncle">Uncle</option>
                      <option value="Grandfather">Grandfather</option>
                      <option value="Grandmother">Grandmother</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Friend">Friend</option>
                      <option value="In-Law">In-Law</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      What is your second personal representative full name?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="name"
                      name="personalRep2"
                      placeholder="e.g John Doe"
                      value={this.state.personalRep2}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      What is your relationship with this person?{' '}
                    </Label>
                    <CustomInput
                      type="select"
                      id="personalRep2Relationship"
                      name="personalRep2Relationship"
                      value={this.state.personalRep2Relationship}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select relationship</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                      <option value="Uncle">Uncle</option>
                      <option value="Grandfather">Grandfather</option>
                      <option value="Grandmother">Grandmother</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Friend">Friend</option>
                      <option value="In-Law">In-Law</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: this.state.hasChildren == '1' ? 'block' : 'none'
                    }}
                    className="mb-4"
                  >
                    <Label className="text-lg">
                      What is your first guardian's full name?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="name"
                      name="guardian1"
                      placeholder="e.g John Doe"
                      onChange={this.handleUserInput}
                      value={
                        this.state.maritalStatus === 'Married'
                          ? this.state.spouseName
                          : this.state.guardian1
                      }
                      disabled={this.state.maritalStatus === 'Married'}
                    />
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: this.state.hasChildren == '1' ? 'block' : 'none'
                    }}
                    className="mb-4"
                  >
                    <Label className="text-lg">
                      What is your relationship with this person?{' '}
                    </Label>
                    <CustomInput
                      type="select"
                      id="guardian1Relationship"
                      name="guardian1Relationship"
                      value={
                        this.state.maritalStatus === 'Married'
                          ? 'Spouse'
                          : this.state.guardian1Relationship
                      }
                      disabled={this.state.maritalStatus === 'Married'}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select relationship</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                      <option value="Uncle">Uncle</option>
                      <option value="Grandfather">Grandfather</option>
                      <option value="Grandmother">Grandmother</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Friend">Friend</option>
                      <option value="In-Law">In-Law</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup
                    className="mb-4"
                    style={{
                      display: this.state.hasChildren == '1' ? 'block' : 'none'
                    }}
                  >
                    <Label className="text-lg">
                      What is your second guardian's full name?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="name"
                      name="guardian2"
                      placeholder="e.g John Doe"
                      value={this.state.guardian2}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>

                  <FormGroup
                    className="mb-4"
                    style={{
                      display: this.state.hasChildren == '1' ? 'block' : 'none'
                    }}
                  >
                    <Label className="text-lg">
                      What is your relationship with this person?{' '}
                    </Label>
                    <CustomInput
                      type="select"
                      id="guardian2Relationship"
                      name="guardian2Relationship"
                      value={this.state.guardian2Relationship}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select relationship</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                      <option value="Uncle">Uncle</option>
                      <option value="Grandfather">Grandfather</option>
                      <option value="Grandmother">Grandmother</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Friend">Friend</option>
                      <option value="In-Law">In-Law</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      Would you like to include a provision declaring that any
                      disputes arising under this will should be subject to
                      non-judicial arbitration? If so, please identify an
                      individual or organization below that would serve as the
                      arbitrator.{' '}
                    </Label>
                    <CustomInput
                      type="select"
                      id="hasArbitration"
                      name="hasArbitration"
                      onChange={this.handleUserInput}
                      value={this.state.hasArbitration}
                      bsSize="lg"
                    >
                      <option value="">Select answer</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </CustomInput>
                  </FormGroup>

                  <FormGroup
                    style={{
                      display:
                        this.state.hasArbitration == '1' ? 'block' : 'none'
                    }}
                    className="mb-4"
                    id="arbitratorQuestion"
                  >
                    <Label className="text-lg">
                      What is the name of the arbitrator?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="name"
                      name="arbitratorName"
                      placeholder="e.g Imam John Doe"
                      value={this.state.arbitratorName}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : (
                  ''
                )}
              </CardFooter>
            </Card>
          </div>

          <div id={`step-6`}>
            <UncontrolledAlert
              color="primary"
              style={{ fontSize: '16px', backgroundColor: '#002E5D' }}
            >
              <div className="alert-message">
                <p>
                  Under Islamic law, you can leave up to 1/3 of your estate to
                  discretionary beneficiaries of your choice, so long as they
                  are not already entitled to inherit from your estate. This
                  amount can be given to charitable organizations, other family
                  members or non-Muslim relatives, or can be used to make up for
                  missed prayers, fasts, or pilgrimage etc.{' '}
                </p>
              </div>
            </UncontrolledAlert>

            <Card id="gift1">
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Al Wasiyah - Discretionary Bequests
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Form>
                  <FormGroup className="mb-4">
                    <Label className="text-lg">
                      Would you like to make any discretionary gifts?
                    </Label>
                    <CustomInput
                      type="select"
                      name="hasDonations"
                      id="hasDonations"
                      value={this.state.hasDonations}
                      bsSize="lg"
                      onChange={this.handleUserInput}
                    >
                      <option value="">Select answer</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </CustomInput>
                    {orgs[org] ? (
                      <p className="text-primary mt-1">
                        Select yes if you want to add donations to{' '}
                        {orgs[org].name}
                      </p>
                    ) : (
                      ''
                    )}
                  </FormGroup>

                  <FormGroup
                    style={{
                      display: this.state.hasDonations == '1' ? 'block' : 'none'
                    }}
                    className="mb-4"
                    id="childQuestion"
                  >
                    <Label className="text-lg">
                      How many total discretionary gifts you want to include?{' '}
                    </Label>
                    <Input
                      bsSize="lg"
                      type="number"
                      name="numDonations"
                      value={this.state.numDonations}
                      onChange={this.handleUserInput}
                    />
                  </FormGroup>

                  <FormGroup>
                    <CustomInput
                      type="switch"
                      name="giftConfirmation"
                      bsSize="lg"
                      id="giftConfirmation"
                      onChange={this.handleToggleChange}
                      label="Toggle this box to confirm that your dollar amount donations do not exceed 1/3rd of your estate."
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : (
                  ''
                )}
              </CardFooter>
            </Card>

            <Card id="gift2" style={{ display: 'none' }}>
              <CardHeader>
                <CardTitle
                  style={{
                    fontSize: '24px',
                    fontWeight: '300',
                    textAlign: 'center'
                  }}
                  tag="h5"
                >
                  Al Wasiyah - Discretionary Bequests
                </CardTitle>
              </CardHeader>

              <CardBody>
                {this.state.hasDonations == '1' ? (
                  <div>
                    {donationDetails}
                    {this.state.donationNames.length <
                    this.state.numDonations ? (
                      <Form>
                        <CardTitle
                          style={{
                            fontSize: '18px',
                            fontWeight: '300',
                            textAlign: 'center'
                          }}
                          tag="h5"
                        >
                          Gift Form {this.state.donationNames.length + 1}
                        </CardTitle>
                        <FormGroup>
                          {orgs[org] &&
                          !this.state.donationNames.includes(orgs[org].name) ? (
                            <div>
                              <p className="text-primary">
                                Many supporters of the {orgs[org].name} choose
                                to leave gifts to charity in their will in order
                                to have a lasting impact on the causes they care
                                about. Click on the below button to pre-fill the
                                form for {orgs[org].name}.
                              </p>
                              {/* <input className="orgButton" type="button" value={orgs[org].name} onClick={this.addExternalOrgDetails} /> */}
                              <button
                                style={{
                                  height: '3rem',
                                  width: '8rem',
                                  fontSize: '0.90rem'
                                }}
                                className="btn btn-submit btn-primary"
                                type="button"
                                onClick={this.addExternalOrgDetails}
                              >
                                {orgs[org].name}
                              </button>
                              <button
                                style={{
                                  height: '3rem',
                                  width: '8rem',
                                  fontSize: '0.90rem'
                                }}
                                className="btn btn-submit btn-primary"
                                type="button"
                                onClick={this.addExternalOrgDetails}
                              >
                                Other
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label>Whom do you want to gift? </Label>
                          <CustomInput
                            type="select"
                            id="donationRecipientType"
                            name="customSelect"
                            // value={orgs[org] && !this.state.donationNames.includes(orgs[org].name) ? orgs[org].recipientType : ""}
                          >
                            <option value="">Select Entity</option>
                            <option value="Individual">
                              An individual (eg. "my nepher Adam")
                            </option>
                            <option value="Group">
                              A group of people (e.g., "all of my living
                              grandchildren")
                            </option>
                            <option value="Organization">
                              An organization (e.g., "Masjid Al-Rahmah in
                              Phoenix")
                            </option>
                          </CustomInput>
                        </FormGroup>

                        <FormGroup>
                          <Label>
                            What is the name of the person/organization you want
                            to gift?{' '}
                          </Label>
                          <Input
                            type="name"
                            // value={orgs[org] && !this.state.donationNames.includes(orgs[org].name) ? orgs[org].name : ""}
                            id="donationName"
                            name="name"
                            placeholder="e.g Alisa Doe"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label>What do you want to gift? </Label>
                          <CustomInput
                            type="select"
                            id="donationType"
                            name="customSelect"
                          >
                            <option value="">Select Entity</option>
                            <option value="Dollar">
                              Dollar amount (eg. $5,000)
                            </option>
                            <option value="Percent">
                              Percentage of estate (eg. 5%)
                            </option>
                          </CustomInput>
                        </FormGroup>

                        <FormGroup>
                          <Label>What is the Amount in $? </Label>
                          <Input
                            type="name"
                            id="donationAmount"
                            name="name"
                            placeholder="Amount in Dollars($)"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Button onClick={this.addGift.bind(this)}>
                            Add Gift {this.state.donationNames.length + 1}{' '}
                            Details
                          </Button>
                        </FormGroup>

                        <FormGroup>
                          {this.state.error ? (
                            <p className="text-danger">{this.state.error}</p>
                          ) : (
                            ''
                          )}
                        </FormGroup>
                      </Form>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <p>
                    You have no discretionary gifts to be added. Continue to
                    next step.
                  </p>
                )}
              </CardBody>
            </Card>
          </div>

          <div id={`step-7`}>
            <UncontrolledAlert
              color="primary"
              style={{ fontSize: '16px', backgroundColor: '#002E5D' }}
            >
              <div className="alert-message">
                <p>
                  Review your answers below. When you’re ready, click on the
                  "Create Will" button at the bottom of the page to create your
                  will.
                </p>
              </div>
            </UncontrolledAlert>

            <Card>
              <CardHeader>
                <h2>Personal Information</h2>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Full Legal Name</em>
                      </h5>
                      <h4>{this.state.name}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Email</em>
                      </h5>
                      <h4>{this.state.email}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Date Of Birth</em>
                      </h5>
                      <h4>
                        {this.state.birthDay.month} {this.state.birthDay.day},{' '}
                        {this.state.birthDay.year}
                      </h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Gender</em>
                      </h5>
                      <h4>{this.state.gender}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Address</em>
                      </h5>
                      <h4>{this.state.address}</h4>
                      <h4>
                        {this.state.city}, {this.state.state}
                      </h4>
                      <h4>{this.state.postalCode}</h4>
                    </div>
                    <div className="mb-4">
                      <button
                        className="btn btn-warning float-right"
                        onClick={() => {
                          this.gotBackFormNumber(13)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h2>Family Information</h2>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Marital Status</em>
                      </h5>
                      <h4>{this.state.maritalStatus}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Children</em>
                      </h5>
                      <h4>{this.state.hasChildren === '1' ? 'Yes' : 'No'}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Is biological father alive?</em>
                      </h5>
                      <h4>{this.state.hasFather === '1' ? 'Yes' : 'No'}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Is biological mother alive?</em>
                      </h5>
                      <h4>{this.state.hasMother === '1' ? 'Yes' : 'No'}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Have any living full siblings?</em>
                      </h5>
                      <h4>{this.state.hasSiblings === '1' ? 'Yes' : 'No'}</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.maritalStatus == 'Married'
                            ? 'block'
                            : 'none'
                      }}
                    >
                      {this.state.maritalStatus == 'Married'
                        ? marriageDetail
                        : ''}
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                    >
                      <h5 className="text-primary">
                        <em>Number of Sons</em>
                      </h5>
                      <h4>{this.state.numSons}</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                    >
                      <h5 className="text-primary">
                        <em>Number of Daughters</em>
                      </h5>
                      <h4>{this.state.numDaughters}</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display: this.state.hasFather == '1' ? 'block' : 'none'
                      }}
                    >
                      <h5 className="text-primary">
                        <em>Father's Name</em>
                      </h5>
                      <h4>{this.state.fatherName}</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display: this.state.hasMother == '1' ? 'block' : 'none'
                      }}
                    >
                      <h5 className="text-primary">
                        <em>Mother's Name</em>
                      </h5>
                      <h4>{this.state.motherName}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Is paternal grandfather alive?</em>
                      </h5>
                      <h4>
                        {this.state.hasPaternalGrandfather == '1'
                          ? 'Yes'
                          : 'No'}
                      </h4>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                    >
                      <h4 className="text-primary">Children Information</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                    >
                      {this.state.numSons > 0 ? sonDetails : <h4>No Sons</h4>}
                      {this.state.numDaughters > 0 ? (
                        daughterDetails
                      ) : (
                        <h4>No Daughters</h4>
                      )}
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        display:
                          this.state.hasSiblings == '1' ? 'block' : 'none'
                      }}
                    >
                      <h4 className="text-primary">Siblings Information</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasSiblings == '1' ? 'block' : 'none'
                      }}
                    >
                      {this.state.numBrothers > 0 ? (
                        brotherDetails
                      ) : (
                        <h4>No Brothers</h4>
                      )}
                      {this.state.numSisters > 0 ? (
                        sisterDetails
                      ) : (
                        <h4>No Sisters</h4>
                      )}
                    </div>

                    <div
                      style={{
                        display:
                          this.state.numSons == '' ||
                          this.state.numSons == '0' ||
                          this.state.hasPaternalGrandfather == '1' ||
                          (this.state.hasFather == '0' &&
                            (this.state.numSons == '' ||
                              this.state.numSons == '0'))
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasPaternalGrandfather == '1'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>Paternal grandfather's name</em>
                        </h5>
                        <h4>{this.state.grandFatherName}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.numSons == '' ||
                            this.state.numSons == '0'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>Do you have grandchildren?</em>
                        </h5>
                        <h4>
                          {this.state.hasGrandchildren == '1' ? 'Yes' : 'No'}
                        </h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasFather == '0' &&
                            (this.state.numSons == '' ||
                              this.state.numSons == '0')
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            Do you have any living halfsibling from your father?
                          </em>
                        </h5>
                        <h4>
                          {this.state.hasFatherHalfSiblings == '1'
                            ? 'Yes'
                            : 'No'}
                        </h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasFather == '0' &&
                            (this.state.numSons == '' ||
                              this.state.numSons == '0')
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            Do you have any living halfsibling from your mother?
                          </em>
                        </h5>
                        <h4>
                          {this.state.hasMotherHalfSiblings == '1'
                            ? 'Yes'
                            : 'No'}
                        </h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasFather == '0' &&
                            (this.state.numSons == '' ||
                              this.state.numSons == '0')
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            Do you have any living nephews from a full brother?
                          </em>
                        </h5>
                        <h4>{this.state.hasNephews == '1' ? 'Yes' : 'No'}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasFather == '0' &&
                            (this.state.numSons == '' ||
                              this.state.numSons == '0')
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            Do you have any living uncles from your father's
                            side?
                          </em>
                        </h5>
                        <h4>{this.state.hasUncles == '1' ? 'Yes' : 'No'}</h4>
                      </div>
                    </div>

                    <div
                      style={{
                        display:
                          this.state.hasGrandchildren == '1' ||
                          this.state.hasFatherHalfSiblings == '1' ||
                          this.state.hasMotherHalfSiblings == '1' ||
                          this.state.hasNephews == '1' ||
                          this.state.hasUncles == '1'
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasGrandchildren == '1'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many grandsons from your sons do you have?
                          </em>
                        </h5>
                        <h4>{this.state.numGrandsons}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasGrandchildren == '1'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many granddaughters from your sons do you have?
                          </em>
                        </h5>
                        <h4>{this.state.numGranddaughters}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasFatherHalfSiblings == '1'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many half brothers from your father do you have?
                          </em>
                        </h5>
                        <h4>{this.state.numFatherHalfBrothers}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasFatherHalfSiblings == '1'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many half sisters from your father do you have?
                          </em>
                        </h5>
                        <h4>{this.state.numFatherHalfSisters}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasMotherHalfSiblings == '1'
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many half siblings from your mother do you have?
                          </em>
                        </h5>
                        <h4>{this.state.numMotherHalfSiblings}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasNephews == '1' ? 'block' : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many nephews from your full brother do you have?
                          </em>
                        </h5>
                        <h4>{this.state.numNephews}</h4>
                      </div>
                      <div
                        className="mb-4"
                        style={{
                          display:
                            this.state.hasUncles == '1' ? 'block' : 'none'
                        }}
                      >
                        <h5 className="text-primary">
                          <em>
                            How many living uncles from your father's do you
                            have?
                          </em>
                        </h5>
                        <h4>{this.state.numUncles}</h4>
                      </div>
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        display:
                          this.state.hasGrandchildren == '1' ? 'block' : 'none'
                      }}
                    >
                      <h4 className="text-primary">
                        Grandchildren Information
                      </h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasGrandchildren == '1' ? 'block' : 'none'
                      }}
                    >
                      {this.state.numGrandsons > 0 ? grandsonDetails : ''}
                      {this.state.numGranddaughters > 0
                        ? granddaughterDetails
                        : ''}
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        display:
                          this.state.hasFatherHalfSiblings == '1' ||
                          this.state.hasMotherHalfSiblings == '1'
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <h4 className="text-primary">
                        Half Siblings Information
                      </h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasFatherHalfSiblings == '1' ||
                          this.state.hasMotherHalfSiblings == '1'
                            ? 'block'
                            : 'none'
                      }}
                    >
                      {this.state.numFatherHalfBrothers > 0 ? (
                        fatherHalfBrotherDetails
                      ) : (
                        <h4>No father half brothers.</h4>
                      )}
                      {this.state.numFatherHalfSisters > 0 ? (
                        fatherHalfSisterDetails
                      ) : (
                        <h4>No father half sisters.</h4>
                      )}
                      {this.state.motherHalfSiblingDetails > 0 ? (
                        motherHalfSiblingDetails
                      ) : (
                        <h4>No mother half siblings</h4>
                      )}
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        display: this.state.hasUncles == '1' ? 'block' : 'none'
                      }}
                    >
                      <h4 className="text-primary">Uncles Information</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display: this.state.hasUncles == '1' ? 'block' : 'none'
                      }}
                    >
                      {this.state.numUncles > 0 ? uncleDetails : ''}
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        display: this.state.hasNephews == '1' ? 'block' : 'none'
                      }}
                    >
                      <h4 className="text-primary">Nephews Information</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display: this.state.hasNephews == '1' ? 'block' : 'none'
                      }}
                    >
                      {this.state.numNephews > 0 ? nephewDetails : ''}
                    </div>
                    <div className="mb-4">
                      <button
                        className="btn btn-warning float-right"
                        onClick={() => {
                          this.gotBackFormNumber(11)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h2>Finance Information</h2>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Profession</em>
                      </h5>
                      <h4>{this.state.profession}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Approximate Total Assets</em>
                      </h5>
                      <h4>{this.state.assetClass}</h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Assets</em>
                      </h5>
                      <h4>
                        {this.state.assets.length > 0
                          ? this.state.assets
                              .map(function (asset) {
                                return asset.value
                              })
                              .join(', ')
                          : 'Not Declared'}
                      </h4>
                    </div>
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Do you have any outstanding debts?</em>
                      </h5>
                      <h4>{this.state.hasDebt == '1' ? 'Yes' : 'No'}</h4>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display: this.state.hasDebt == '1' ? 'block' : 'none'
                      }}
                    >
                      <h5 className="text-primary">
                        <em>How many outstanding debts do you have?</em>
                      </h5>
                      <h4>{this.state.numDebts}</h4>
                    </div>

                    <div className="mb-2">
                      {this.state.numDebts > 0 ? (
                        <h4 className="text-primary">Debts Information</h4>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mb-4">
                      {this.state.numDebts > 0 ? debtsDetails : ''}
                    </div>
                    <div className="mb-4">
                      <button
                        className="btn btn-warning float-right"
                        onClick={() => {
                          this.gotBackFormNumber(5)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h2>Agents Information</h2>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="mb-4">
                      <Row form>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Personal Representative 1 Name</em>
                            </h5>
                            <h4>
                              {this.state.maritalStatus == 'Married'
                                ? this.state.spouseName
                                : this.state.personalRep1}
                            </h4>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Personal Representative 1 Relationship</em>
                            </h5>
                            <h4>
                              {this.state.maritalStatus == 'Married'
                                ? 'Spouse'
                                : this.state.personalRep1Relationship}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Personal Representative 2 Name</em>
                            </h5>
                            <h4>{this.state.personalRep2}</h4>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Personal Representative 2 Relationship</em>
                            </h5>
                            <h4>{this.state.personalRep2Relationship}</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="mb-4"
                      style={{
                        display:
                          this.state.hasChildren == '1' ? 'block' : 'none'
                      }}
                    >
                      <Row form>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Guardian 1 Name</em>
                            </h5>
                            <h4>
                              {this.state.maritalStatus == 'Married'
                                ? this.state.spouseName
                                : this.state.guardian1}
                            </h4>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Guardian 1 Relationship</em>
                            </h5>
                            <h4>
                              {this.state.maritalStatus == 'Married'
                                ? 'Spouse'
                                : this.state.guardian1Relationship}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Guardian 2 Name</em>
                            </h5>
                            <h4>{this.state.guardian2}</h4>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <h5 className="text-primary">
                              <em>Guardian 2 Relationship</em>
                            </h5>
                            <h4>{this.state.guardian2Relationship}</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        display:
                          this.state.hasArbitration == '1' ? 'block' : 'none'
                      }}
                      className="mb-4"
                    >
                      <h5 className="text-primary">
                        <em>What is the name of the arbitrator?</em>
                      </h5>
                      <h4>{this.state.arbitratorName}</h4>
                    </div>
                    <div className="mb-4">
                      <button
                        className="btn btn-warning float-right"
                        onClick={() => {
                          this.gotBackFormNumber(3)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h2>Donation Information</h2>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="mb-4">
                      <h5 className="text-primary">
                        <em>Would you like to make any discretionary gifts?</em>
                      </h5>
                      <h4>{this.state.hasDonations == '1' ? 'Yes' : 'No'}</h4>
                    </div>
                    {this.state.hasDonations == '1' &&
                    this.state.numDonations > 0
                      ? donationDetails
                      : ''}
                    <div className="mb-4">
                      <button
                        className="btn btn-warning float-right"
                        onClick={() => {
                          this.gotBackFormNumber(2)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>

            <Card
              style={{
                display:
                  this.state.showCreateButton || this.state.showSpinner
                    ? 'block'
                    : 'none'
              }}
            >
              <CardFooter>
                <Row>
                  <Col lg="4" />
                  <Col lg="4">
                    {this.state.showCreateButton ? (
                      <Button
                        className="btn-pill btn-success"
                        size="lg"
                        style={{ width: '100%' }}
                        id="createWill"
                        onClick={this.createWill.bind(this)}
                      >
                        Create My Will
                      </Button>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col lg="4" />
                </Row>
                <Row>
                  <Col lg="2" />
                  <Col lg="9">
                    <p className="mt-2">
                      The inheritance shares calculations are powered by the{' '}
                      <a
                        href="https://www.pa-kabmadiun.go.id/waris/irth.html"
                        target="_blank"
                      >
                        IRTH Islamic inheritance program
                      </a>
                      .
                    </p>
                  </Col>
                  <Col lg="1" />
                </Row>
                <Row>
                  <Col lg="3" />
                  <Col lg="6">
                    {this.state.showSpinner ? (
                      <Row>
                        <Col lg="2" />
                        <Col lg="2">
                          <Circles
                            type="Watch"
                            color="#002E5D"
                            height="40"
                            width="40"
                          />
                        </Col>
                        <Col lg="6">
                          <h3 className="mt-2 text-primary">
                            Creating Will...
                          </h3>
                        </Col>
                        <Col lg="2" />
                      </Row>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col lg="3" />
                </Row>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const Quiz = (authUser) => (
  <Container fluid className="text-center">
    <Row>
      <Col lg="2"></Col>
      <Col lg="8">
        <WizardVariant authUser={authUser} variant="arrows" color="primary" />
      </Col>
      <Col lg="2"></Col>
    </Row>
  </Container>
)

const QuizPage = (match) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <div className="mt-4">
          <Quiz authUser={authUser} match={match} />
        </div>
      ) : (
        ''
      )
    }
  </AuthUserContext.Consumer>
)

const condition = (authUser) => !!authUser

export default compose(withAuthorization(condition))(QuizPage)
